import React, { useState } from "react"
import Identicon from "identicon.js"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import { Link } from "gatsby"

function toHex(txt: string) {
  const encoder = new TextEncoder()
  return Array.from(encoder.encode(txt))
    .map(b => b.toString(16).padStart(2, "0"))
    .join("")
}

export type ArticleItemProps = {
  writer: string
  title: string
  picture?: string
  label?: string
  to: string
  amountUpvotes: number
}

// For when account updated the profile picture
const refreshTwitterPicture = async (
  handle: string,
  setPicture: (x: string) => void
) => {
  const res = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}updateTwitterPicture`,
    {
      method: "POST",
      body: JSON.stringify({ handle }),
    }
  )
  const result = await res.json()
  setPicture(result.picture)
}

export const ArticleItem = (props: ArticleItemProps) => {
  const pictureUrl =
    props.picture ||
    `data:image/png;base64,${new Identicon(
      toHex(props.writer).padStart(15, "-"),
      {
        background: [0, 0, 0, 255],
        size: 140,
      }
    ).toString()}`

  const [picture, setPicture] = useState(pictureUrl)

  return (
    <Row style={{ marginBottom: "10px", width: "100%" }}>
      <Col xs={3}>
        <img
          src={picture}
          style={{
            maxWidth: "60px",
            maxHeight: "60px",
            width: "100%",
            borderRadius: "50px",
          }}
          onError={() => refreshTwitterPicture(props.writer, setPicture)}
        />
      </Col>
      <Col
        xs={9}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        <Link to={props.to}>
          <span style={{ float: "right" }}>
            {props.amountUpvotes || 0} <i className="bi bi-hand-thumbs-up"></i>
          </span>
          <h5
            style={{
              marginBottom: "0",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {props.label === "Influencer" && (
              <i style={{ marginRight: "3px" }} className="bi bi-star-fill"></i>
            )}
            {props.label === "DAO" && (
              <i
                style={{ marginRight: "3px" }}
                className="bi bi-collection-fill"
              ></i>
            )}
            {props.writer}
          </h5>
          {props.title}
        </Link>
      </Col>
    </Row>
  )
}
