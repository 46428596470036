import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import { Page } from "../components/Page"

import Card from "react-bootstrap/esm/Card"
import Button from "react-bootstrap/esm/Button"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import roadmap from "../../static/roadmap.jpg"
import { ArticleItem } from "../components/ArticleItem"
import { WriterItem } from "../components/WriterItem"

type ListDocument = {
  slug: string
  wallet: string
  createTime: number
  updateTime: number
  title: string
  amountUpvotes: number
  writer?: { twitterHandle: string; picture: string; label: string }
}

type Writer = {
  twitterHandle: string
  label: string
  picture?: string
}

const listDocuments = async (by: string) => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}listDocuments/desc/${by}`
  )
  return resultRes.json()
}

const listWriters = async () => {
  const resultRes = await fetch(
    `${process.env.GATSBY_BACKEND_ENDPOINT}listWriters/desc`
  )
  return resultRes.json()
}

export default function Home() {
  const [newestDocuments, setNewestDocuments] = useState([] as ListDocument[])
  const [mostPopularDocuments, setMostPopularDocuments] = useState(
    [] as ListDocument[]
  )
  const [writers, setWriters] = useState([] as Writer[])

  useEffect(() => {
    listDocuments("date").then(setNewestDocuments)
    listDocuments("upvotes").then(setMostPopularDocuments)
    listWriters().then(setWriters)
  }, [])

  return (
    <Page>
      <Row>
        <Col lg={8}>
          <img src={roadmap} style={{ width: "100%", borderRadius: "5px" }} />
          <hr
            style={{
              border: "1px solid #333",
              fontSize: "20px",
              height: "8px",
              borderRadius: "5px",
              margin: "10px 1vh 5px 25%",
              opacity: 1,
              boxShadow: "1px 1px 3px",
              width: "calc((50% - 1vh*4)/3)",
              float: "left",
            }}
          />
          <hr
            style={{
              border: "1px solid #333",
              fontSize: "20px",
              height: "8px",
              borderRadius: "5px",
              margin: "10px 1vh 5px 1vh",
              opacity: 1,
              boxShadow: "1px 1px 3px",
              width: "calc((50% - 1vh*4)/3)",
              float: "left",
            }}
          />
          <hr
            style={{
              border: "1px solid #333",
              fontSize: "20px",
              height: "8px",
              borderRadius: "5px",
              margin: "10px 25% 5px 1vh",
              opacity: 1,
              boxShadow: "1px 1px 3px",
              width: "calc((50% - 1vh*4)/3)",
              float: "left",
            }}
          />
          <div style={{ clear: "both" }} />

          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title>Newest articles</Card.Title>
              <div className="twoToOneColumnCount">
                {newestDocuments.slice(0, 6).map(document => (
                  <ArticleItem
                    key={document.slug}
                    to={`/d/${document.slug}`}
                    label={document.writer?.label}
                    writer={document.writer?.twitterHandle || document.wallet}
                    title={document.title}
                    amountUpvotes={document.amountUpvotes}
                    picture={document.writer?.picture}
                  />
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title>Most popular</Card.Title>
              <Card.Text className="oneToOneColumnCount">
                {mostPopularDocuments.slice(0, 5).map(document => (
                  <ArticleItem
                    key={document.slug}
                    to={`/d/${document.slug}`}
                    label={document.writer?.label}
                    writer={document.writer?.twitterHandle || document.wallet}
                    title={document.title}
                    amountUpvotes={document.amountUpvotes}
                    picture={document.writer?.picture}
                  />
                ))}
              </Card.Text>
              <Card.Link
                as={Link}
                to="/popular"
                style={{ textAlign: "center", display: "block" }}
              >
                <Button size="sm" variant="outline-primary">
                  More
                </Button>
              </Card.Link>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Newest writers</Card.Title>
              <Card.Text className="oneToOneColumnCount">
                {writers.map(writer => (
                  <div key={writer.twitterHandle}>
                    <WriterItem
                      writer={writer.twitterHandle}
                      label={writer.label}
                      picture={writer.picture}
                    />
                  </div>
                ))}
              </Card.Text>
              <Card.Link
                as={Link}
                to="/writers"
                style={{ textAlign: "center", display: "block" }}
              >
                <Button size="sm" variant="outline-primary">
                  Show more
                </Button>
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  )
}
